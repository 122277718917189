<template>
  <section class="desgin">
    <Loader :loading="showLoader" />
    <div class="row flex-grow">

      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="editCounter" class="forms-sample">
              <div class="row">
                <div class="col-md-6">
                  <div role="group" class="form-group">
                    <label class="d-block">OUR JOURNEY BEGAN</label>
                    <div>
                      <input type="text" placeholder="Title" v-model="counter.start" class="form-control">
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <label class="d-block">YEARS OF TRUST</label>
                    <div>
                      <input type="text" placeholder="Title" v-model="counter.years" class="form-control">
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <label class="d-block">PROJECT ACROSS  CITIES IN KERALA</label>
                    <div>
                      <input type="text" placeholder="Title" v-model="counter.cities" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div role="group" class="form-group">
                    <label class="d-block">PROJECT ACROSS  KERALA</label>
                    <div>
                      <input type="text" placeholder="Title" v-model="counter.project" class="form-control">
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <label class="d-block">CUSTOMERS</label>
                    <div>
                      <input type="text" placeholder="Title" v-model="counter.customer" class="form-control">
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <label class="d-block">SQ.FT BUILT UP  AREA</label>
                    <div>
                      <input type="text" placeholder="Title" v-model="counter.sqft" class="form-control">
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn mr-2 btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import Loader from "../components/Loader";
import { sliderApi } from "../api";
export default {
  name: 'counter',
  components: {
    Loader,
  },
  data() {
    return {
      showLoader: false,
      counter: "",
    };
  },
  mounted() {
    this.CounterById();
  },
  methods: {

    async CounterById() {
      this.showLoader = true;
      const { data } = await sliderApi.getCounterById(`1`);
      this.counter = data.data.data;
      console.log(this.counter);
      this.showLoader = false;
    },

    async editCounter() {
      this.showLoader = true;
      const data = await sliderApi.updateCounter(this.counter._id, {
        ...this.counter,
      });
      this.CounterById();
      this.showLoader = false;
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.message,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.message,
        });
      }
    },
  }
}
</script>
